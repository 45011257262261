<template>
  <v-row class="pl-2 pt-8 mb-5">
    <v-col cols="8" class="pl-5 pb-0">
      <p class="mb-0 pb-2 generic-text">Olá,</p>
      <v-row class="pl-2">
        <p style="font-size: 36px" class="mb-0 main-title text-truncate">
          {{ $auth.user().name.split(' ')[0] }}{{ $auth.user().name.split(' ')[0].length > 13 ? '...' : '' }}
        </p>
      </v-row>
<!--      <v-chip class="mt-3" color="#CCE9F9" pill>-->
<!--        <v-avatar left>-->
<!--          <v-img src="/img/icons/home/gems.svg" style="width: 25px; height: 30px"/>-->
<!--        </v-avatar>-->
<!--        <p class="generic-text mb-0" style="font-size: 14px; font-weight: bold">671 pontos</p>-->
<!--      </v-chip>-->
    </v-col>
    <v-col id="MenuNick" cols="3" class="px-0 mx-0">
      <v-row align="center" justify="end">
        <v-col class="pl-0" cols="6">
          <img
            @click="$util.link('menu-nick')"
            src="/img/nick/nick-smile-icon.svg"
            style="width: 56px; height: 56px;"
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="8" class="pl-8 pb-0">
      <v-row>
        <p v-if="$auth.user().hasCompanyConnection == true" style="width: 70vw; font-size: 17px;position: relative; top:-20px; left: -2px" class="mb-0 pt-2 main-text">
          {{ hasConnectionText }}</p>
      </v-row>
    </v-col>
    <v-col style="margin-top: -10px" id="chatMessage" cols="3" class="px-0 mx-0">
      <v-row align="center" justify="end">
        <v-col class="pl-0 py-0" cols="6">
          <v-badge :content="getCountUnreadMessages()" :value="getCountUnreadMessages()" color="red"  overlap >
            <img
              @click="$util.link('message')"
              src="/img/icons/chat/chat.svg"
              style="width: 56px; height: 56px;"
            >
          </v-badge>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MessageService from "../../services/socialnetwork/MessageService";
export default {
  name: "protecaoHeader",
  created() {
    this._messageService = new MessageService()
    this.loadCountUnreadMessages();
  },
  computed: {
    hasConnectionText(){
      if(this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length > 0){
        return `conectado à ${this.$auth.user().beneficiaries[0].contractName}`
      }
      return ''
    }
  },
  methods: {
    ...mapMutations(['setCountUnreadMessages']),
    ...mapGetters(["getCountUnreadMessages"]),
    loadCountUnreadMessages(){
      this._messageService.CountUnreadMessages()
        .then((response) =>{
          this.setCountUnreadMessages(response.data);
        })
        .catch(() => {
          this.setCountUnreadMessages(null);
        })
    }
  }
}
</script>

<style scoped>

</style>
