<template lang="pug">
  transition(name="fade")
    div
      .tour-backdrop(v-if="opened")
      .tour-invisibledrop(v-if="opened")
      .tour-container(v-if="opened")
        .tour-box
          .tour-content.flex-center(v-if="!isEmpty(currentStepObj)")
            tour-controlls(
              style="z-index: 11; position: absolute; top: 0; width: 100%"
              :steps="steps"
              :currentStep="currentStep"
              v-model="currentStep")
            .step-float-content#step-float(
              :style="stepFloatStyle")
              .step-media.flex-center(
                v-if="hasMedia('top')")
                .step-media-image(
                  :class="currentStepObj.animation ? [currentStepObj.animation, 'animated'] : null"
                  :style="stepMediaStyle")
              .step-default-content.left.alt(
                v-if="!hasContentSlot"
                :class="[currentStepObj.animation ? [currentStepObj.animation, 'animated'] : null, hasMedia('top') ? 'p-top' : 'p-bottom']")
                .step-description {{currentStepObj.description}}
                .step-extra-action(v-if="currentStepObj.extraAction")
                  v-row(
                    id="rowButtons")
                    v-col(
                      class="colStepBtn"
                      cols="9"
                    )
                      v-img(src="/img/icons/arrow-back.svg"
                        id="BackStepBtn"
                        v-bind="currentStepObj.extraActionBack.props"
                        @click="currentStep > 0 ? currentStep-- : currentStep = currentStep") {{currentStepObj.extraActionBack.label}}
                    v-col(
                      class="colStepBtn"
                      cols="3"
                    )
                      v-img(src="/img/icons/arrow-forward.svg"
                        id="NextStepBtn"
                        v-bind="currentStepObj.extraAction.props"
                        @click="currentStep < steps.length-1  ? currentStep++ : opened = false; clear()") {{currentStepObj.extraAction.label}}
              .step-media.flex-center(v-if="hasMedia('bottom')")
                .step-media-image(
                  v-if="currentStepObj.media"
                  :class="currentStepObj.animation ? [currentStepObj.animation, 'animated'] : null"
                  :src="currentStepObj.media.path"
                  :style="stepMediaStyle")
              slot(v-else name="content")
            v-btn.close-btn(@click="clearAndClose" icon)
              v-img(src="/img/icons/close.svg" style="max-width: 28px; margin-right: 155vw")
</template>
<script>
import Item from "../structure/item/item"
import ItemSection from "../structure/item/itemSection"
import TourControlls from './TourControlls'
import { isEmpty } from 'lodash'
export default {
  name: 'Tour',
  components: {
    Item,
    ItemSection,
    TourControlls
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      default: v => []
    },
  },
  data () {
    return {
      touch: {
        startX: 0,
        endX: 0
      },
      stepAnchor: {
        left: 0,
        top: 0
      },
      currentStep: null,
      currentStepObj: null,
      opened: false,
      showNick: false,
      stepFloatTop: 0,
      stepFloatLeft: 0
    }
  },
  computed: {
    stepMediaStyle () {
      return {
        height: this.currentStepObj.media.size,
        width: this.currentStepObj.media.size,
        backgroundImage: `url(${this.currentStepObj.media.path})`
      }
    },
    hasContentSlot () {
      return !!this.$slots.content
    },
    stepFloatStyle () {
      return {
        top: `${this.stepFloatTop}px`,
        left: `${this.stepFloatLeft}px`
      }
    }
  },
  watch: {
    value () {
      this.currentStep = 0
      this.opened = this.value
      this.formatElements()
    },
    opened () {
      this.$emit('input', this.opened)
    },
    currentStep () {
      this.formatElements()
      this.$emit('update', this.currentStep)
    }
  },
  async created () {
    this.currentStep = 0
    await this.formatElements()
    this.opened = this.value
  },
  methods: {
    isEmpty,
    touchstart(event) {
      this.touch.startX = event.touches[0].clientX;
      this.touch.endX = 0;
    },
    touchmove(event) {
      this.touch.endX = event.touches[0].clientX;
    },
    touchend() {
      if(this.touch.endX < this.touch.startX)
        if (this.currentStep < this.steps.length-1) {
          this.currentStep++;
        }else{
          this.opened = false;
          this.clear();
        }

      if(this.touch.endX > this.touch.startX)
        if (this.currentStep > 0){
          this.currentStep --
        }

    },
    hasMedia (position) {
      return this.currentStepObj && this.currentStepObj.media && this.currentStepObj.media.position === position
    },
    async formatElements () {
      // Clear all changes
      this.clear()
      if (this.currentStep === -1) {
        this.opened = false
        return
      }
      const step = this.steps[this.currentStep]

      // Pick element
      const target = document.getElementById(step.target)
      target.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})

      // Set scroll to element

      setTimeout(async () => {
        this.stepAnchor.top = 0
        this.stepAnchor.left = 0
        if (target) {
          this.stepAnchor = this.getCoords(target)
          target.classList.add('tour-focused-step')
          this.currentStepObj = step
          const selfElem = document.getElementById('step-float')
          if (selfElem) {
            setTimeout(() => {
              this.getFronTargetPosition({
                targetElem: target,
                selfElem,
                anchor: step.anchor,
                self: step.self,
                offset: [0,-80],
              })
            }, 140)
          }
        }
      }, 70)
      if (step.action) {
        const targetAct = document.getElementById(step.action)
        if (targetAct) {
          targetAct.click()
        }
      }
    },
    // el: DOM element. Ex: el = document.getElementById(elemId)
    // anchor: { vertical: { top, center, bottom }, horizontal: { left, middle, right, start, end } }
    // self: { vertical: { top, center, bottom }, horizontal: { left, middle, right, start, end } }
    getFronTargetPosition ({ targetElem, selfElem, anchor, self, offset }) {

      // Get offset of step
      const [ offsetX, offsetY ] = offset

      // Get position params of step float content
      const selfParams = selfElem.getBoundingClientRect()

      // Get position params of target element
      const anchorParams = targetElem.getBoundingClientRect()

      // Get custom positions from step
      const anchorArr = anchor ? anchor.split(' ') : []


      // Switch custom postion and calculate X/Y coordinates
      if (anchorArr.length) {
        const [ anchorVert, anchorHoriz ] = anchorArr

        // Define top position based on custom step: top, center, bottom
        switch (anchorVert) {
          case 'bottom':
            this.stepFloatTop = (anchorParams.top + anchorParams.height) + offsetY + 170
            break;
          case 'top':
            this.stepFloatTop = (anchorParams.top - selfParams.height) - (offsetY + 110)
            break;
          case 'center':
            this.stepFloatTop = (anchorParams.top + anchorParams.height) + offsetY + 120
            break;
          default:
            this.stepFloatTop = anchorParams.top
            break;
        }

        // Define left position based on custom step: left, middle, right
        switch (anchorHoriz) {
          case 'left':
            this.stepFloatLeft = (anchorParams.left + anchorParams.width) + offsetX
            break;
          case 'middle':
            this.stepFloatLeft = (anchorParams.left + (anchorParams.width / 2)) - (selfParams.width / 2) + offsetX
            break;
          case 'right':
            this.stepFloatLeft = (anchorParams.left - anchorParams.width) - (offsetX + 130)
            break;
          default:
            this.stepFloatLeft = anchorParams.left
            break;
        }
      }
    },
    clear () {
      for (const i in this.steps) {
        const trg = document.getElementById(this.steps[i].target)
        if (trg) {
          trg.classList.remove('tour-focused-step')
        }
      }
    },
    clearAndClose(){
      this.opened = false;
      setTimeout(this.clear,200)
    },
    getCoords (elem) {
      const box = elem.getBoundingClientRect()
      const body = document.body
      const docEl = document.documentElement
      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
      const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft
      const clientTop = docEl.clientTop || body.clientTop || 0
      const clientLeft = docEl.clientLeft || body.clientLeft || 0
      const top  = box.top +  scrollTop - clientTop
      const left = box.left + scrollLeft - clientLeft
      return { top: Math.round(top), left: Math.round(left) }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$el.addEventListener('touchstart', event => this.touchstart(event));
      this.$el.addEventListener('touchmove', event => this.touchmove(event));
      this.$el.addEventListener('touchend', () => this.touchend());
    }, 0)
  }
}
</script>
<style>
.tour-focused-step{
  z-index: 10 !important;
  position: relative !important;
}
.none-tour-focused{

}
.colStepBtn{
  padding: 0;
}
#rowButtons{
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
}
#BackStepBtn{
  width: 26px;
}
#NextStepBtn{
  width: 26px;
}
.tour-box{
  position: relative;
}
.step-title{
  font-size: 16px
}
.step-description{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #292867;
}
.btn{
  transition: all 300ms ease;
}
.btn.disable{
  opacity: .5;
}
.flex-center{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.close-btn{
  z-index: 11;
  position: fixed;
  right: 24px;
  top: 24px;
}
.tour-controlls{
  position: fixed;
  bottom: 125;
  left: 0;
  width: 100vw;
}
.tour-content {
}
.step-float-content{
  max-width: 100%;
  overflow: auto;
  position: fixed;
  z-index: 11;
  color: black;
  -webkit-transition: all 300ms cubic-bezier(0.74, 0, 0.47, 1);
  transition: all 300ms cubic-bezier(0.74, 0, 0.47, 1);
}
.step-default-content{
  max-width: 300px;
  background: white;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 3px 3px 8px rgba(0,0,0,0.4);
  border: 2px solid #292867;
}
.step-default-content::before {
  content: "";
  position: absolute;
  height: var(--h,15px);
  width: var(--w,15px);
  background: inherit;
  transform:scale(var(--x,1),var(--y,1));
}

.step-default-content.p-bottom::before{
  top: 100%;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  margin-top: -1px;
}
.step-default-content.p-bottom.alt::before{
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.step-default-content.p-top::before{
  bottom: 100%;
  clip-path: polygon(0 100%, 100% 100%, 50% 0);
  margin-bottom: -1px;
}
.step-default-content.p-top.alt::before{
  clip-path: polygon(0 100%, 100% 100%, 100% 0);
}
.step-default-content.p-left::before{
  right: 100%;
  clip-path: polygon(100% 0, 100% 100%,0% 50%);
}
.step-default-content.p-left.alt::before{
  clip-path: polygon(100% 0, 100% 100%,0% 100%);
}
.step-default-content.p-right::before{
  left: 100%;
  clip-path: polygon(0% 0, 0% 100%,100% 50%);
}
.step-default-content.p-right.alt::before{
  clip-path: polygon(0% 0, 0% 100%,100% 100%);
}
.step-default-content.right::before{
  right:var(--p,20px);
}
.step-default-content.left::before {
  left:var(--p,20px);
}
.step-default-content.top::before{
  top:var(--p,20px);
}
.step-default-content.bottom::before {
  bottom:var(--p,20px);
}
.tour-container {
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 99;
}
.tour-backdrop {
  background: rgba(0, 0, 0, 0.82);
  backdrop-filter: blur(4px);
  height: calc(100vh);
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 9;
}
.tour-invisibledrop{
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0);
  height: calc(100vh);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 11;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.step-media{
  transition: width 400ms ease;
}
.step-media-image {
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 16px;
}
.animated{
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: relative;
}
.animated.enterLeftBottom{
  animation-name: enterLeftBottom;
}
.animated.zoomIn{
  animation-delay: 300ms;
  transform: scale(0);
  animation-name: zoomIn;
  animation-timing-function: ease;
}
.animated.fadeIn{
  animation-delay: 200ms;
  opacity: 0;
  animation-name: fadeIn;
  animation-timing-function: ease;
}
@keyframes enterLeftBottom {
  0%   {
    left: -100px;
    top: 100px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
@keyframes zoomIn {
  0%   {
    transform: scale(0)
  }
  50%   {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}
@keyframes fadeIn {
  0%   {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}
</style>
