<template>
  <v-container
    fluid
    id="chartContainer"
    :class="fitContainer ? 'fitContainer' : ''"
  >
    <NickBanner />
    <cadastroDialog :dialog.sync="cadastroDialogBool"></cadastroDialog>
    <v-row
      align="start"
      dense
      :class="
        (fitContainer ? 'fitContainer' : '') +
        (inCarousel ? '' : ' pb-1') +
        ' px-1 pt-1'
      "
      :style="inCarousel ? { 'padding-bottom': '22px !important' } : {}"
      v-show="!cadastroDialogBool && ready"
      :key="app.reloadableKey"
    >
      <v-col cols="12">
        <homeHeader />
      </v-col>
      <v-col
        cols="12"
        class="pa-0"
        id="ucanttouchthis"
        v-touch="swipeChart ? { left: () => swipe('left') } : {}"
      >
        <v-row no-gutters justify="center" id="v-step-1">
          <div id="chart" @click="_initializeChart()"></div>
        </v-row>
        <subItensList class="pa-0 ma-0"></subItensList>

      </v-col>

        <v-col
          class="mt-8 px-0 mx-0"
          cols="12"
          v-if="storiesList"
        >
          <v-row>
            <v-col class="px-2 mx-0" style="padding-bottom: 0" cols="12">
              <v-container class="feed-bg">
                <div id="divisorFeed" class="cell-span"/>
                <v-stories
                  id="score"
                  :bookmark="bookmarkList"
                  :total-new="totalNew"
                  @updateTotalizersFeed="updateTotalizersFeed"
                />
              </v-container>
              <ProgressiveLoader class="container" :disabled="busy" @reachedEnd="getTimeline">
                <v-timeline
                  id="v-step-9"
                  v-if="storiesList"
                  :timeline-items="storiesList"
                  :refresh-timeline="getTimeline"
                  @updateTotalizersFeed="updateTotalizersFeed"
                />
              </ProgressiveLoader>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
    <TourHome :ready="loadTour"/>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { protecoesUtil } from "./protecoesUtil";
import cadastroDialog from "./../app/cadastroDialog.vue";
import protecoesListaItens from "./protecoesListaItens.vue";
import slider from "./slider";
import slidersumatizador from "./slidersumarizador";
import slideritens from "./slideritens";
import { Touch } from "vuetify/lib/directives";
import { mapState, mapMutations } from "vuex";

import stories from "../../components/protecoes/stories";
import timeline from "../../components/protecoes/timeline";
import ProgressiveLoader from "../../components/progressive-loader/index";
import homeHeader from "./homeHeader";
import subItensList from "./subItensList";

import { parseISO, formatDistance } from "date-fns";
import pt from "date-fns/locale/pt";

import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import html2canvas from "html2canvas";
import EuProtegidoService from "@/services/euprotegido/EuProtegidoService";
import WebSocketService from "./../../services/euprotegido/WebSocketService";

import TourHome from '../../components/tour/TourHome'

import NickBanner from "../../components/nick-banner/NickBanner";
import UserTourService from "../../services/security/UserTour";

export default {
  name: 'Home',
  components: {
    "slide-sumarizador": slidersumatizador,
    "slide-item": slideritens,
    protecoesListaItens,
    cadastroDialog,
    slider,
    "v-stories": stories,
    "v-timeline": timeline,
    ProgressiveLoader,
    homeHeader,
    subItensList,
    TourHome,
    NickBanner
  },
  directives: {
    Touch,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: false,
    },
    showChartTotalizer: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
      default: "",
    },
    totalColor: {
      type: String,
      default: "#FFF",
    },
    chartSize: {
      type: String,
      default: "99%",
    },
    initialChartCircleSize: {
      type: Number,
      default: 118,
    },
    eachChartCircleSize: {
      type: Number,
      default: 14,
    },
    inCarousel: {
      type: Boolean,
      default: false,
    },
    allItensDescription: {
      type: String,
      default: "Família",
    },
    showCadastroDialog: {
      type: Boolean,
      default: false,
    },
    fitContainer: {
      type: Boolean,
      default: true,
    },
    btnAdd: {
      type: Boolean,
      default: true,
    },
    onlyMonths: {
      type: Boolean,
      default: false,
    },
    swipeChart: {
      type: Boolean,
      default: false,
    },
    desafiosnick: {
      type: Boolean,
      default: false,
    },
    naoesquece: {
      type: Boolean,
      default: false,
    },
    beneficios: {
      type: Boolean,
      default: false,
    },
    stepType: {
      type: String,
      default: "steps",
    },
  },
  beforeMount() {
  this._userTourService = new UserTourService();
    let url = window.location.href;
  this._userTourService.getCurrentUserTour()
    .then((response) =>{
      if(response.data && !url.includes('?showTour')){
        if(!response.data.visualizedTour){
          this.$util['showTour']()
          this.$util.link('home-tour', null, null);
        }
      }
    })
  },
  async mounted() {
    this.bookmarkView()
    this.initialize();
    // this.controlTour();

    setTimeout(() => {
      this.getScreenshot();
    }, 1600)
  },
  watch: {
    defaultCarteira: function (newValue, oldValue) {
      var carteira = this.getCarteiraByName(newValue);

      if (carteira.tipo) {
        this.calculateSaldoSubItens(carteira);
      } else {
        this.calculateSaldoSubItens();
      }
    },
    defaultTipo: function (newValue, oldValue) {
      if (oldValue) {
        var selectedTipo = this.tipos.filter(function (tipo) {
          return tipo.name == newValue;
        })[0];

        var tlink = selectedTipo.link
          ? selectedTipo.link
          : "detail-iten-protecoes";
        this.$util.link(tlink, {
          title: selectedTipo.name,
          listID: selectedTipo.id,
        });
      }
    },
    "menu.showtour"() {
      this.controlTour();
    },
  },
  data() {
    return {
      totalNew: 0,
      tagPoliticaDePrivacidade: 'politicaPrivacidadeShowed',
      loadTour: false,
      busy: true,
      totalPages: 1,
      page: 0,
      items: [],
      initializing: false,
      ready: false,
      chartConfig: null,
      carteiras: [],
      defaultCarteira: false,
      tipos: [],
      defaultTipo: false,
      cadastroDialog: null,
      allSaldoCalculated: false,
      storiesList: [],
      timelineList: [],
    };
  },
  computed: {
    ...mapState(["app", "menu"]),
    cadastroDialogBool: {
      get: function () {
        if (this.$auth.userIsSF()) return false;

        return this.cadastroDialog != null
          ? this.cadastroDialog
          : this.showCadastroDialog;
      },
      set: function (value) {
        this.cadastroDialog = value;
        if (value == false) this.initialize();
      },
    },
    calculateColumSize() {
      if (this.items.length <= 3) return 3;
      else return 3;
    },
  },
  created() {
    // this.$router.push({name: 'root'})
    this._socialNetworkService = new SocialNetworkService();
    this._euProtegidoService = new EuProtegidoService();
    this._websocket = new WebSocketService();
    this.getTimeline();
    this.connectWebSocket();
  },
  methods: {
    ...mapMutations([
      "refreshReloadableKey",
      "toggleShowTour",
      "showmsg",
      "loading",
      "refreshScreenshot"
    ]),
    updateTotalizersFeed(){
      this.totalNew--;
    },
    connectWebSocket(){
      this._websocket.Connect();
    },
    getScreenshot() {
      html2canvas(document.body, {
        backgroundColor: null,
      }).then((canvas) => {
        let image = canvas.toDataURL( 'image / png');
        this.refreshScreenshot(image);
      })
    },
    getTimeline() {
      if (this.page < this.totalPages) {
        let getUser = this.$auth.user();
        this.loading(true);
        let stories = [];
        this.busy = true;
        this._socialNetworkService.countFeedsByEntity().then((_res) => {
        })
        this._socialNetworkService
          .FindFeedItemUser(getUser.id, this.page)
          .then((_res) => {
            if (_res.data && _res.data.totalElements > 0) {
              this.totalPages = _res.data.totalPages;
              _res.data.content.forEach(function (elem) {
                let store = {
                  id: elem.id,
                  origemthumb: elem.thumbnail,
                  origem: elem.name ? elem.name : "---",
                  titulo: elem.description ? elem.description : "---",
                  medias: [],
                  descricao: elem.contents,
                  typemidia: "",
                  linkimage: null,
                  linkvideo: null,
                  lida: elem.view && elem.view > 0,
                  lidaOrigem: elem.view && elem.view > 0,
                  exibirtudo: false,
                  curtidas: elem.like_count ? elem.like_count : 0,
                  like: elem.like && elem.like > 0,
                  likeClick: 0,
                  likeOrigem: elem.like && elem.like > 0,
                  likeId: elem.like && elem.like > 0 ? elem.like : 0,
                  bookmark: elem.bookmark && elem.bookmark > 0,
                  bookmarkOrigem: elem.bookmark && elem.bookmark > 0,
                  bookmarkId:
                    elem.bookmark && elem.bookmark > 0 ? elem.bookmark : 0,
                };

                //Corrigi possível erro no vínculo do back
                if (store.curtidas == 0 && store.likeId > 0) {
                  store.curtidas = 1;
                }

                let datehoracompleta = elem.creation_date.split("T");
                if (datehoracompleta.length == 2) {
                  let datehora = datehoracompleta[0].split("-");
                  if (datehora.length == 3) {
                    let dataCriacao = parseISO(datehoracompleta[0]);
                    store.datahora = formatDistance(dataCriacao, new Date(), {
                      addSuffix: true,
                      locale: pt,
                    });
                  }
                }

                stories.push(store);
              });
            }
          })
          .catch(() =>
            this.showmsg({
              text: "Erro ao carregar timeline!",
              type: "error",
            })
          )
          .finally(() => {
            if (stories && stories.length > 0) {
              stories.forEach((feed) => {
                this.storiesList.push(feed);
              });
              this.storiesList.forEach((feed) => {
                if (feed.typemidia == "") {
                  this.getMidia(feed);
                }
              });
              this.busy = false;
              this.page++;
            }
            this.loading(false);
          });
      } else {
        this.busy = true;
      }
    },
    getMidia(feed) {
      this._socialNetworkService
        .GetMidiaFeed(feed.id)
        .then((_res) => {
          // this.totalNew = _res.data.length
          if (_res.data && _res.data.length > 0) {
            feed.medias = _res.data
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    bookmarkView(){
      this._socialNetworkService.FindFeedItemUserByParams(this.userId, 0, "POST", true)
        .then(response => {
          this.totalNew = response.data.totalElements
        })
        .catch(() => {
        })
    },
    getTipo(item) {
      switch (item.descricao) {
        case "Minha Saúde":
          return "item-saude";
          break;
        default:
          return "";
      }
    },
    goToLinkPandemia() {
      window.location.href = "https://protejase.covid19.firecodeit.net/";
    },
    swipe(direction) {
      if (direction == "left") {
        if (this.$route.params.activeTab != 1)
          this.$util.link("protecoes", { activeTab: 1 });
      }
    },
    getCarteiraByName(name) {
      return this.carteiras.filter(function (carteira) {
        return carteira.name == name;
      })[0];
    },
    conectarEmpresa() {
      this.cadastroDialogBool = true;
    },
    is100() {
      return protecoesUtil.calculateTotal(this.items) >= 100;
    },
    initialize() {
      this.chartConfig = null;
      this.ready = false;
      this.allSaldoCalculated = false;

      this.carteiras = [];
      this.carteiras.push({
        name: this.allItensDescription,
        tipo: false,
        perfil: "*",
      });
      this.carteiras.push({
        name: "José da Silva",
        tipo: "Titular",
        perfil: "H",
      });
      this.carteiras.push({
        name: "Maria da Silva",
        tipo: "Dependente",
        perfil: "M",
      });
      this.carteiras.push({
        name: "Enzo da Silva",
        tipo: "Dependente",
        perfil: "C",
      });

      if (!this.showModeInsta && this.$route.params.title === "Minha Saúde") {
        this.showModeInsta = true;
      }

      if (this.showFilter && this.defaultCarteira == false)
        this.defaultCarteira = this.carteiras[0].name;



      if (!this.initializing) {
        this.initializing = true;

        this._euProtegidoService.FindConsolidatedProtectionItensByUserId()
            .then(response => {
              if(response.data) {
                let protectionItens = response.data.protectionItens
                if(protectionItens && protectionItens.length > 0) {
                  for(var i=0; i < protectionItens.length; i++){
                    this.items.push({
                      color: protectionItens[i].style,
                      percentual: protectionItens[i].value,
                      descricao: protectionItens[i].key,
                      link: protectionItens[i].key
                    })
                  }

                } else {
                  this.items = this.getSkeletonChartItens()
                }
              } else {
                this.items = this.getSkeletonChartItens()
              }

            }).catch(error => {
              this.items = this.getSkeletonChartItens()
            }).finally(()=>{
              this.calculateSaldoSubItens();
              this.initializeChart();
              this.initializing = false;
              this.ready = true;
              this.loadTour = true
            })
      }
    },
    getSkeletonChartItens(){
      let itens = []
      for(var i=0; i < 3; i++){
        itens.push({ color: '#9E9E9E', percentual: 0.1, descricao: '',  link: '' })
      }
      return itens
    },
    calculateSaldoSubItens(filterByCarteira, myItems) {
      this.allSaldoCalculated = false;

      var carteiras = this.carteiras;
      if (filterByCarteira != undefined)
        carteiras = new Array(filterByCarteira);

      var items = this.items;
      if (myItems != undefined) items = myItems;

      for (const [index, item] of items.entries()) {
        if (item.subitens) {
          item.saldo = false;
          item.subitens
            .then((subitens) => {
              var total = 0;
              var todo = 0;

              for (const [cindex, carteira] of carteiras.entries()) {
                for (const [sindex, subiten] of subitens.entries()) {
                  if (carteira.tipo) {
                    var access = false;
                    if (carteira.perfil == "H") {
                      access = subiten.homem;
                    } else if (carteira.perfil == "M") {
                      access = subiten.mulher;
                    } else if (carteira.perfil == "C") {
                      access = subiten.crianca;
                    }
                    if (access) {
                      if (access.do) {
                        total += 1;
                        if (!access.status.ok) todo += 1;
                      }
                    }
                  }
                }
              }

              item.saldo = { total, todo };
            })
            .finally(() => {
              var allSaldoCalculated = items.filter(function (item) {
                return item.saldo == false;
              });

              if (allSaldoCalculated.length == 0) {
                this.allSaldoCalculated = true;
                // this.refreshReloadableKey();
              }
            });
        }
      }
    },
    initializeChart(c) {
      var thiz = this;
      setTimeout(() => {
        if (!c) c = 0;
        var container = document.querySelectorAll("#chartContainer #chart");
        if (c > 8) {
        } else if (container.length > 0) {
          container.forEach(function (elem) {
            thiz._initializeChart();
          });
        } else {
          c++;
          thiz.initializeChart(c);
        }
      }, 250);
    },
    _initializeChart() {
      Highcharts.chart("chart", this.config());
    },
    buildSize(lastsize, csize) {
      var size = csize ? csize : this.eachChartCircleSize;
      var ini = lastsize ? lastsize : this.initialChartCircleSize;
      var end = ini - size;
      var arr = [ini + "%", end + 1 + "%", end];
      return arr;
    },
    buildChartItemBackg(item, size) {
      return {
        outerRadius: size[0],
        innerRadius: size[1],
        backgroundColor: Highcharts.Color(item.color).setOpacity(0.2).get(),
        borderWidth: 0,
      };
    },
    buildChartItem(item, size) {
      return {
        name: item.descricao,
        data: [
          {
            color: item.color,
            radius: size[0],
            innerRadius: size[1],
            y: item.percentual,
          },
        ],
      };
    },
    buildTotalItem() {
      var ptotal = protecoesUtil.calculateTotal(this.items);
      var item = {
        descricao: "Protegido",
        percentual: this.$util.round(ptotal, 0),
        color: this.totalColor,
      };
      var size = this.buildSize(null, 8);
      return { item, size };
    },
    config() {
      if (!this.chartConfig) this.chartConfig = this._config();
      return this.chartConfig;
    },
    _config() {
      var config = this.baseConfig();

      if (this.items.length > 0) {
        var lastSize = null;

        if (this.showChartTotalizer) {
          var total = this.buildTotalItem();

          config.pane.background.push(
            this.buildChartItemBackg(total.item, total.size)
          );

          var totalChartItem = this.buildChartItem(total.item, total.size);
          totalChartItem.selected = true;
          config.series.push(totalChartItem);

          var lastSize = total.size[2];
        }

        for (const [index, item] of this.items.entries()) {
          if (item.percentual) {
            var size = this.buildSize(lastSize);
            lastSize = size[2];

            config.pane.background.push(this.buildChartItemBackg(item, size));
            config.series.push(this.buildChartItem(item, size));
          }
        }
      }
      return config;
    },
    baseConfig() {
      var _ptotal = protecoesUtil.calculateTotal(this.items);
      var ptotal = this.$util.round(_ptotal, 0);

      return {
        chart: {
          type: "solidgauge",
          height: this.chartSize,
          backgroundColor: "transparent",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: ptotal > 0 ?
            "<span style='font-size: 35px;color: #EA4965'>" +
            "<span style='font-weight: bold'>" +
            ptotal + "</span>%</span>" +
            "<span v-if='ptotal > 0' style='font-size: 20px;color: #587BBB;font-weight: bold'><br />PROTEGIDO</span>" :
              "<p style='margin: 0; padding-right: 30px; padding-left: 30px; font-size: 16px; color: #757575;font-weight: bold;'>Você ainda não tem cálculo de proteção.</p>",
          useHTML: true,
          floating: true,
          verticalAlign: "middle",
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false,
            },
            linecap: "round",
            stickyTracking: false,
            rounded: true,
          },
          series: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [],
        },
        series: [],
        tooltip: { enabled: false },
        credits: {
          enabled: false,
        },
      };
    },
    getStepTour() {
      if (this.stepType) {
        if (this.stepType == "step") {
          return this.steps;
        } else if (this.stepType == "step1") {
          return this.steps1;
        }
      } else return this.steps;
    },
  },
};
</script>

<style>
#chart {
  width: 75%;
  height: auto;
}

.highcharts-subtitle {
  text-align: center !important;
}
.agendamento-status {
  border: 1px solid #333 !important;
  border-radius: 5px;
}

.customcolparent {
  display: table;
  margin-top: 2px;
  margin-bottom: 2px;
}

.customcol {
  display: table-cell;
  vertical-align: middle;
  border-radius: 5px;
  height: 110px;
  margin-right: 10px;
  padding: 5px 10px;
  max-width: 250px;
  min-width: 50px;
  width: auto;
}

.swiper-slide {
  width: auto !important;
  margin-right: 10px;
}

.v-chip__content {
  align-items: center;
}

.v-input__slot {
  box-shadow: none !important;
}

.v-input__slot .transparent {
  box-shadow: none !important;
}
.v-chip.v-size--small {
  padding-right: 100%;
}
.v-input__append-inner {
  padding: initial;
  /* padding-left: -2px !important; */
}
.v-input__icon {
  justify-content: initial !important;
}

.v-input__control .v-text-field__details {
  display: none;
}

.feed-bg {
  background: white !important;
  box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.15);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  /*height: 100%;*/
}

.cell-span {
  background: #DBD9DE;
  border-radius: 5px;
  width: 50px;
  height: 5px;
  left: 50%;
  position: relative;
  margin-left: -25px;
}
</style>
