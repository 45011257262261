<template>
  <div>
    <v-card style="border-top-right-radius: 40px !important;border-top-left-radius: 40px !important;" elevation="0" rounded id="feedCard">
      <v-row justify="space-around" class="mt-2 ml-0">
        <v-col cols="9">
          <p class="generic-title" style="font-size: 22px; padding-left: 5px">Fique por dentro</p>
        </v-col>
        <v-col class="pl-0" cols="3">
          <v-chip v-if="totalNew > 0" style="color: white" color="tertiary">
            {{ totalNew > 1 ?( totalNew > 99 ?  `+99 novos` : `${totalNew} novos` ) : (totalNew == 1 ? '1 novo' : '') }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        align-content="center"
        justify="center"
      >
        <v-col style="padding-bottom: 0" cols="12">
          <v-row>
            <swiper style=" padding-left: 8px" class="mt-5 ml-5" :options="swiperOption" ref="mySwiper">
              <template class="ml-3">
                <template>
                  <swiper-slide key="swiper-slide-primeiro">
                    <div
                      class="storie"
                    >
                      <div
                        @click="getBookmark({ originName: 'Minha coleção', origemthumb: $auth.user().profilePicture })"
                        class="text-center pr-2 pt-2 storie-item"
                      >
                        <img
                          :src="$auth.user().profilePicture"
                        />
                        <p class="main-text" style="font-size: 12px" id="sua-colecao-3">
                          Minha coleção
                        </p>
                      </div>
                    </div>
                  </swiper-slide>
                </template>
              </template>
              <template v-for="(_group, aindex) in entityList">
                <template>
                  <swiper-slide
                    :key="'swiper-slide' + aindex"
                  >
                    <div class="storie mr-4" style="margin-bottom: -20px;">
                      <v-card
                        color="#EA4965"
                        elevation="0"
                        style="
                          z-index: 1;
                          display: block;
                          margin-left: auto;
                          margin-right: auto;
                          border-radius: 50%;
                          padding: 3px;
                          height: 75px;
                          width: auto;"
                      >
                        <v-card
                          color="white"
                          elevation="0"
                          style="
                          z-index: 1;
                          display: block;
                          margin-left: auto;
                          margin-right: auto;
                          border-radius: 50%;
                          padding: 4px;
                          height: 67px;
                          width: auto;"
                        >
                          <v-card
                            color="blue"
                            elevation="0"
                            style="
                              z-index: 1;
                              display: block;
                              margin-left: auto;
                              margin-right: auto;
                              border-radius: 50%;
                              padding: 1px;
                              height: 65px;
                              width: auto;"
                          >
                            <div
                              @click="getTimelineByEntity(_group)"
                              class="storie-item"
                              style="padding: 0; margin: 0;"
                            >
                              <img
                                style="display: block;
                                    margin-left: auto;
                                    margin-right: auto"
                                :src="_group.thumbnail"
                              />

                              <p class="main-text text-center" align="center" style="font-size: 12px; padding-top: 7px">
                                {{ _group.entity }}
                              </p>
                            </div>
                          </v-card>
                        </v-card>
                      </v-card>
                    </div>
                  </swiper-slide>
                </template>
              </template>
            </swiper>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import "swiper/dist/css/swiper.css";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import { mapMutations } from "vuex";
import {formatDistance, parseISO} from "date-fns";
import pt from "date-fns/locale/pt";

export default {
  name: 'Stories',
  components: {
    swiperSlide,
    swiper,
  },
  props: {
    totalNew: {
      type: Number,
      required: true
    },
    bookmark: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      entityList: [],
      swiperOption: {
        slidesPerView: "auto",
      },
      postList: [],
      bookmarkList: [],
    };
  },
  beforeCreate() {
    this._socialNetworkService = new SocialNetworkService()
  },
  created() {
    this.getEntityByType()
  },
  methods: {
    ...mapMutations(['loading', 'showmsg']),
    getEntityByType(){
      this.loading(true);
      this._socialNetworkService.countFeedsByEntity()
        .then(({ data }) => {
          this.entityList = data;
        })
        .catch(() => {
          this.showmsg({
            text: "Não é possivel carregar as entidades.",
            type: "red"
          });
        })
      .finally(() => {
        this.loading(false);
      })
    },
    getTimelineByEntity(entity){
      this.loading(true);
      this._socialNetworkService.FindFeedItemUserByEntity(this.$auth.user().id, entity.entity_id)
        .then(({ data }) => {
          this.postList = data.content;
        })
        .catch(() => {
          this.showmsg({
            text: "Não é possivel carregar a lista de postagens.",
            type: "red"
          });
        })
        .finally(() => {
          this.loading(false);
          this.$router.push({
            name: 'timelineStory',
            params: {
              timelineList: this.postList,
              avatarImg: entity.thumbnail,
              bookmarkTitle: entity.entity,
            }
          })
        })
    },
    getBookmark({ originName, origemthumb }) {
      this._socialNetworkService.FindBookmarkItemUser(this.$auth.user().id)
        .then(({ data }) => {
          this.bookmarkList = data.content;
        })
        .catch(() => {
          this.showmsg({
            text: "Não é possivel carregar a coleção.",
            type: "red"
          });
        })
        .finally(() => {
          this.loading(false);
          this.$router.push({
            name: 'timelineStory',
            params: {
              timelineList: this.bookmarkList,
              avatarImg: origemthumb,
              bookmarkTitle: originName,
            }
          })
        })
    },
    newsBackgroundColor(_group){
      if (_group.list.length>0){
        return '#EA4965';
      }else{
        return 'transparent';
      }
    },
    updateTotalizersFeed(){
      this.$emit('updateTotalizersFeed');
    },
  },
};
</script>

<style>
.meu-story {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--v-primary2);
  font-size: 10px !important;
  position: relative !important;
  margin-left: -20px;
  margin-top: -5px;
}

.load .container {
  padding: 0px !important;
}
.storie {
  cursor: pointer;
}
.storie-item {
  padding-top: 5px;
  margin-left: 0px;
}

.storie-item img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.storie-header {
  display: flex;
  align-items: center;
}

.storie-header-avatar {
  margin: 10px;
}

.storie-header-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 3px var(--v-primary2);
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.15));
}

.txt-origem {
  font-size: 12px;
  text-align: center;
}

.nao-lida {
  border: solid 3px var(--v-primary2);
}
.lida {
  border: solid 2px;
  opacity: 0.3;
}

.load {
  position: fixed;
  background-color: var(--v-secondary2);
  z-index: 5;
  top: 48px;
  right: 0;
  bottom: 60px;
  left: 0;
  display: flex;
  justify-content: center;
}

.text-description {
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: 3px;
}

html,
body {
  width: 100vw;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  background: #fafafa;
}

#app {
  transition: margin 0.3s ease-out;
}

</style>
