<template>
  <v-dialog
    content-class="rounded-t-0"
    persistent
    fullscreen
    id="dialog"
    :value="show"
  >
   <v-overlay style="border-radius: 0px;">
     <v-row align="end" justify="center" style="width: 100vw; height: 100vh;">
       <v-col cols="12" align="center">
         <v-row align="center" justify="center">
           <v-col cols="12">
             <v-card
                 class="balloonDetail"
                 color="white"
                 style="border-radius: 18px; border: #292867 2px solid !important;">
               <v-row style="" justify="end" class="pa-0 ma-0 pr-2 pt-1">
                 <v-col class="pa-0 ma-0" cols="1">
                   <v-icon @click="closeBanner()" class="ma-0 pa-0" color="#292867">fas fa-times</v-icon>
                 </v-col>
               </v-row>
               <v-row class="pa-0 mb-4 mt-0 pt-0" style="height: 65%">
                 <v-col style="color: #292867" class="pt-0" align-self="center" cols="12">
                   <span v-html="description"></span>
                 </v-col>
               </v-row>
             </v-card>
           </v-col>
           <v-col cols="12">
             <img class="pt-4" id="img1" src="/img/nick/nick-umbrella.svg"/>
           </v-col>
         </v-row>
       </v-col>
     </v-row>
   </v-overlay>
  </v-dialog>
</template>

<script>
import SocialNetworkService from '@/services/socialnetwork/SocialNetworkService'
    export default {
      name: "NickBanner",
      data:() => ({
        tag: 'NickBannerShowed',
        show: false,
        userId: 0,
        feedItemId: 0,
        banners: [],
        bannerIndex: 0,
        description: '',
      }),
      methods: {
        getFeedItem(){
          this._socialNetworkService.FindFeedItemUserByParams(this.userId, 0, "BANNER", true)
              .then(response => {
                if(response.data && response.data.content){
                  this.banners = response.data.content
                  this.setBanner();
                }
              })
              .catch(() => {
              })
        },
        setBanner(){
          if(this.bannerIndex < this.banners.length){
            this.description = this.banners[this.bannerIndex].contents
            this.feedItemId = this.banners[this.bannerIndex].id
            this.show = true
          }
        },
        closeBanner() {
          this.saveLike();
          this.show = false;
          setTimeout(() => {
            this.bannerIndex++;
            this.setBanner();
          }, 500);
        },
        saveLike() {
          let feedLike = {
            userId: this.userId,
            feedItemId: this.feedItemId,
            id: 0
          };
          this._socialNetworkService.SaveViewStorie(feedLike)
              .then(() => {
              })
              .catch(() => {
              })
        }
      },
      created() {
        this._socialNetworkService = new SocialNetworkService();
        this.userId = this.$auth.getSession().user.id
        this.getFeedItem();
      }
    }
</script>

<style scoped>

.balloonDetail:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #292867;
  border-right: 10px solid transparent;
  border-top: 10px solid #292867;
  border-bottom: 10px solid transparent;
  right: 130px;
  bottom: -23px;
}

.balloonDetail:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #fff;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  border-bottom: 10px solid transparent;
  right: 128.3px;
  bottom: -16.5px;
}

#dialog {
  border-radius: 0px !important;
}

</style>
