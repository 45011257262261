import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import $auth from '@/common/auth'
import $store from '@/store/store'
//import MessageService from '../../services/socialnetwork/MessageService'

var stompClient = null;
var name = null;
var token = null;
//var _messageService = new MessageService();

export default class WebSocketService {
  constructor() {
  }

  Connect() {
    if($auth.user().websocket)
      return;

    name = $auth.user().name;
    token = $auth.getAccessToken();

    if (name) {
      var socket = new SockJS(process.env.VUE_APP_API_URL +'/euprotegido/websocketApp');
      stompClient = Stomp.over(socket);
      stompClient.debug = null;

      var header = {
        authorization: `${token}`
      }

      stompClient.connect(header, this.ConnectionSuccess);

      let user = $auth.user();
      user.websocket = true;
      $auth.setUser(user);
    }
  }

  ConnectionSuccess() {
    stompClient.subscribe(`/topic/user-${$auth.user().id}`, (data) => WebSocketService.prototype.OnMessageReceived(data));
    stompClient.subscribe(`/topic/allUsers`, (data) => WebSocketService.prototype.OnMessageReceived(data));

    /*stompClient.send("/websocket/chat.newUser", {}, JSON.stringify({
      sender : name,
      type : 'newUser'
    }))*/
  }

  SendMessage(messageContent) {
    if (messageContent && stompClient) {
      var chatMessage = {
        sender : name,
        content : messageContent,
        type : 'CHAT'
      };

      stompClient.send("/websocket/chat.sendMessage", {}, JSON.stringify(chatMessage));
    }
  }

  OnMessageReceived(payload){
    try {
      const message = JSON.parse(payload.body);

      switch(message.type) {
        case "CHAT":
          WebSocketService.prototype.ProcessChatMessage(message)
          break;
        case "CHAT_BOT":
          WebSocketService.prototype.ProcessChatBotMessage(message);
          break;
        case "ALERT":
          WebSocketService.prototype.ProcessAlertMessage(message);
          break;
        default:
          WebSocketService.prototype.ProcessDefaultMessage(message);
      }

    }
    catch(err) {
      //vazio
    }

  }

  ProcessChatMessage(message){
    try {
      let unreadMessages = $store.getters.getCountUnreadMessages;
      $store.commit('setCountUnreadMessages', unreadMessages + 1);

      const content = JSON.parse(message.content);
      const senderId = content.senderEntity.id;
      let hasEntityMessage = false;
      let messagesGroupingBySender = $store.getters.getMessagesGroupingBySender;

      messagesGroupingBySender.forEach(function(m) {
        if (m.senderEntity.id == senderId) {
          m.lastMessage = content.lastMessage;
          m.totalMessages = content.totalMessages;
          m.unreadMessages = content.unreadMessages;
          hasEntityMessage = true;
        }
      });

      if(hasEntityMessage == false)
        messagesGroupingBySender.push(content);

      $store.commit('setMessagesGroupingBySender', messagesGroupingBySender);

      let allMessages = $store.getters.getAllMessages.filter(function( m ) {
        return m.senderId == senderId;
      });

      if(allMessages.length > 0) {
        let allSenderMessages = allMessages[0];
        allSenderMessages.messages.unshift(content.lastMessage);
        $store.commit('setAllMessagesBySender', allSenderMessages);
        //_messageService.MarkMessageAsRead(content.lastMessage.id);
        //let container = document.getElementById ( "notification-body" );
        //container.scrollTop = container.scrollHeight;
        setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
      }


    }
    catch(err) {
      setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
    }

  }

  ProcessChatBotMessage(message){
    try{
      let content = message.content;
      let buttons = [];

      content = content.replace('\\n', '<br>');

      if(content.includes('###')){
        const msg = content.split('###');
        content = msg[0];
        let msgButtons = msg[1];
        if(msgButtons.includes('abrirAtendimento')){
          buttons.push({ label: "Abrir Atendimento", action : 'abrirAtendimento'})
        }
        if(msgButtons.includes('socilitarReembolso')){
          buttons.push({ label: "Solicitar Reembolso", action : 'socilitarReembolso'})
        }
        if(msgButtons.includes('consultarRede')){
          buttons.push({ label: "Consultar Rede", action : 'consultarRede'})
        }
        if(msgButtons.includes('chatBotNao')){
          buttons.push({ label: "Não", action : 'chatBotNao'})
        }
        if(msgButtons.includes('chatBotSim')){
          buttons.push({ label: "Sim", action : 'chatBotSim'})
        }
      }
      //$store.commit('addChatBotMessage', content);
      const chatbotId = $store.getters.getChatBotMessages[0].chatbot;
      let chatBotMessages = $store.getters.getChatBotMessages;

      let allMessages = chatBotMessages.filter(function( m ) {
        return m.chatbot == chatbotId;
      });

      if(allMessages.length > 0) {
        let allSenderMessages = allMessages[0];
        allSenderMessages.messages.unshift({body: content, type:'bot', messageClass: 'bot-message', buttons});
        let chatbotMessages = [{chatbot: chatbotId, messages:  [...allSenderMessages] }];
        $store.commit('setChatBotMessages', chatbotMessages);
        setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
      }
    }
    catch(err) {
      setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
    }
  }

  ProcessAlertMessage(message){
    try{
      const content = JSON.parse(message.content);
      $store.commit('showmsg', {
        text: content.body,
        type: content.type
      });
    }
    catch(err) {
      // vazio
    }
  }

  Disconnect(){
    stompClient.disconnect({}, {});
  }
}

